.bg-white {
    background-color: #ffff;
}

.page-header.gallery:before {
    background-color: transparent!important;
}
.page-header.gallery.page-header-small {
    max-height: 740px;
}
.inner-banner {
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bs-section {
    position: relative;
    margin-top: 85px;

    .sub-header-title {
        color: #ffff;
        z-index: 1;
        position: relative;

        span {
            // background-color: #a52019;
            padding: 0px 15px;
            font-weight: 900;
        }
    }

    &:before {
        background-color: #A63C27;
        opacity: 0.6;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
    }

    .background-layer {
        background: transparent;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &:after {
        background-color: transparent;
        position: absolute;
        content: "";
        display: inline-block;
        top: 0;
        bottom: 0;
        width: 100%;
        left: 0;
    }
}


.bg-cover {
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.schedule-item {
    border-bottom: 1px solid #cad4f6;
}

.pb-9 {
    padding-bottom: 4rem !important;
}

.pt-12 {
    padding-top: 7rem !important;
}


.main-header {
    background-color: rgba(22, 34, 57, 0.95);
    height: 80px;
    position: fixed;
    z-index: 12;
    width: 100%;
    top: 0;
}

.video-overlay {
    position: absolute;
    background-color: rgba(22, 34, 57, 0.85);
    top: 0;
    left: 0;
    bottom: 0px;
    width: 100%;
}

.caption {
    // text-align: center;
    // position: absolute;
    // width: 80%;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%,-50%);
    // color: #fff;
    // -webkit-transform: translate(-50%,-50%);

    color: #fff;
    left: 50%;
    max-width: 880px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;

}

#bg-video {
    min-width: 100%;
    min-height: 100vh;
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
    z-index: -1;
}

.banner-sponsor{
    z-index: 1;
    position: relative;
    // background: white;
    /* opacity: 0.8; */
    // padding: 1rem;
}
.banner-sponsor h2{
    font-size: 20px;
}
.banner-sponsor.coffee-break h2, .banner-sponsor.happy-hour h2{ 
    font-size: 15px;
}
// .bs-section.header-sponsor:before{
//     opacity: 1;
//     background: rgb(166,60,39);
//     background: linear-gradient(90deg, rgba(166,60,39,1) 22%, rgba(255,255,255,1) 79%);
// }

.bs-section.header-sponsor:before {
    opacity: 1;
    // background: rgb(166, 60, 39);
    // background: linear-gradient(90deg, rgb(166, 60, 39) 22%, rgb(255, 255, 255) 79%);
    // background: rgb(166,60,39);
    // /* background: linear-gradient(90deg, rgba(166,60,39,1) 18%, rgba(255,255,255,1) 50%, rgba(166,60,39,1) 85%); */
    // background: rgb(166,60,39);
    // background: linear-gradient(90deg, rgba(166,60,39,1) 10%, rgba(255,255,255,1) 50%, rgba(166,60,39,1) 95%);
    // background: rgb(166,60,39);
    // /* background: linear-gradient(90deg, rgba(166,60,39,1) 5%, rgba(255,255,255,1) 50%, rgba(166,60,39,1) 95%); */
    // background: linear-gradient(90deg, rgba(166,60,39,0.8267682072829132) 28%, rgba(255,255,255,0.5410539215686274) 69%, rgba(166,60,39,0.4990371148459384) 100%);
    // background: linear-gradient(90deg, rgba(166,60,39,1) 28%, rgba(255,255,255,1) 69%, rgba(166,60,39,0.4990371148459384) 100%);
    // background: linear-gradient(90deg, rgba(166,60,39,1) 28%, rgba(255,255,255,0.7987570028011204) 61%, rgba(166,60,39,0.4990371148459384) 100%);
    // background: linear-gradient(90deg, rgba(166,60,39,1) 14%, rgba(255,255,255,1) 54%, rgba(166,60,39,1) 94%);
    // background: linear-gradient(90deg, rgba(166,60,39,1) 14%, rgba(255,255,255,1) 54%, rgba(166,60,39,0.804359243697479) 96%);
    // background: linear-gradient(180deg, rgba(166,60,39,1) 28%, rgba(255,255,255,0.7567401960784313) 65%, rgba(166,60,39,0.8827906162464986) 90%);

        /* background: linear-gradient(180deg, rgb(166, 60, 39) 28%, rgba(255, 255, 255, 0.7567401961) 65%, rgba(166, 60, 39, 0.8827906162) 90%); */
        // background: linear-gradient(0deg, rgba(165,32,25,1) 0%, rgba(255,255,255,0) 50%, rgba(165,32,25,1) 100%);
        /* background: linear-gradient(0deg, rgba(165,32,25,1) 0%, rgba(255,255,255,0) 38%, rgba(255,255,255,0) 62%, rgba(165,32,25,0.8155637254901961) 83%); */
        /* background: linear-gradient(0deg, rgba(165,32,25,1) 13%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 62%, rgba(165,32,25,1) 84%); */
        // background: linear-gradient(0deg, rgba(165,32,25,1) 17%, rgba(255,255,255,1) 37%, rgba(165,32,25,0.7287289915966386) 46%, rgba(255,255,255,1) 54%, rgba(165,32,25,1) 84%); 
        /* background: linear-gradient(0deg, rgba(165,32,25,1) 13%, rgba(255,255,255,1) 35%, rgba(165,32,25,0.2945553221288515) 46%, rgba(255,255,255,1) 54%, rgba(165,32,25,1) 84%);*/
        background: linear-gradient(180deg, rgb(166, 60, 39) 7%, rgba(255, 255, 255, 0.7567401961) 50%, rgba(166, 60, 39, 0.8827906162) 90%)
}
.z-10{
    z-index: 10;
}
.gap-20{
    gap: 20px;
}
.gap-30{
    gap: 30px;
}
.gap-100{
    gap: 100px;
}
.header-sponsor.inner-banner{
    min-height: 380px;
}

.gold-container-ms{
    gap: 30px 30px;
}

@media only screen and (max-width: 523px) {
    .gap-100{
        gap: 0;
    }
    .banner-sponsor h2{
        font-size: 15px;
    }
    img.gold{
        width: 200px;
    }
    .sponsor-logo-ms{
        gap: 25px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .container-sponsor-ms{
        padding: 50px 20px;
    }
    .gold-container-ms{
        gap: 30px 10px;
    }

}


@media only screen and (max-width: 320px) {
    .panel-btn-register{
        display: none;
    }

}

@media only screen and (max-width: 375px) {
    .panel-btn-register{
        display: none;
    }

    .panel-countdown .panel-countdown-inner span {
        margin-right: 30px;
    }
    .meeting-launch-title{
        color: #fff;
    }

}
@media only screen and (max-width: 425px) {
    .sub-header-title{
        font-size: 35px;
    }
}

@media only screen and (max-width: 767px) {
    .meeting-launch-title{
        color: #fff;
    }
    .registration-box{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .gap-100{
        gap: 7px;
    }
}
@media only screen and (max-width: 768px) {
    .gap-100{
        gap: 7px;
    }
    .banner-sponsor{
        padding:0;
    }
}
@media only screen and (max-width: 1024px) {
    .gap-30 {
        gap: 6px;
    }    
    .navbar .navbar-nav .nav-link:not(.btn) {
        font-size: .7rem;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) 
{
    }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}