
.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    label {
      font-size: 14px;
      font-weight: 600;
      color: rgb(34, 34, 34);
    }
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
  }
  


  .table thead th {
    position: sticky;
    top: 0;
    background-color: white;
}

.registration-box{
  box-shadow: 0 30px 50px 0 rgba(1, 1, 1, 0.18);
  padding: 4rem;
  border-radius: 15px;
  margin-bottom: 1em;
  background: #ffffff;
}


.container1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 500px;
}

.box12 {
  flex: 1;
  border: 1px solid #000;
  margin: 0 10px;
  padding: 20px;
  text-align: center;
}

.card-bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
}