.search-bar-container_inner,
.hotel_bar_select {
    background: rgba(255, 255, 255, 1);
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
}

.search-bar-container_top {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.search-bar-container_guestsWrapper {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

.search-bar-container_guests:before {
    left: 1rem;
    margin-right: 0.25em;
}

.search-bar-container_checkIn .search-bar-container_label,
.search-bar-container_checkOut .search-bar-container_label,
.search-bar-container_checkInAndCheckOut .search-bar-container_label,
.search-bar-container_guests .search-bar-container_label {
    font-weight: 700;
    color: #3e2206;
    display: block;
    padding: 0px 14px;
}

.search-bar-container_checkIn,
.search-bar-container_checkOut,
.search-bar-container_checkInAndCheckOut,
.search-bar-container_guests {
    border-right: 1px solid #ccc;
}

.search-bar-container_checkIn:before,
.search-bar-container_checkOut:before {
    left: 1rem;
    margin-right: 0.25em;
}

.search-bar-container_wrapper {
    margin-bottom: 1rem;
    position: relative;
}

.search-bar-container_checkOut {
    border-right-width: 0;
    border-right: 1px solid #ccc;
}

.reservation-cart-container_inner {
    border: 1px solid #d7d7d7;
    background: rgb(255 255 255);
    border-radius: 4px;
    padding: 1rem;
    position: relative;
    h2 {
        margin-bottom: 1rem;
    }
}

.reservation-cart-container_checkIn,
.reservation-cart-container_checkOut {
    display: inline-block;
    vertical-align: top;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.reservation-cart-container_summary {
    margin: 1rem 0;
}

hr {
    background: #ccc;
    border: 0;
    height: 1px;
    margin: 1rem 0;
    padding: 0;
}

.price-summary_price,
.price-summary_total {
    font-size: 1.2rem;
    font-weight: 700;
}

.price-summary_price {
    text-align: right;
}

.reservation-cart-container_hotelDetails {
    display: -ms-flexbox;
    display: flex;
    // -ms-flex-wrap: wrap;
    // flex-wrap: wrap;
}

.reservation-cart-container_checkIn b,
.reservation-cart-container_checkOut b {
    display: block;
}

.search-bar-container_checkIn,
.search-bar-container_checkOut,
.search-bar-container_checkInAndCheckOut,
.search-bar-container_guests {
    background: none;
    border: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    // cursor: pointer;
    text-align: left;
    vertical-align: middle;
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    position: relative;
    color: #333;
    font-weight: 400;
    font-size: 1rem;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

.message_box {
    background: rgba(255, 255, 255, 1);
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    padding: 1rem;
}

.message_container {
    margin-bottom: 1rem;
}

.message_wrapper {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    // justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -ms-flexbox;
    display: flex;
}

.message_content {
    padding-left: 1rem;
    position: relative;
    min-height: 3rem;
    line-height: 2.8rem;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

// .message_type-promoApplied .message_icon::before {
//     display: inline-block;
//     line-height: 1em;
//     font-family: 'spark-icon-fill';
//     font-size: 1em;
//     vertical-align: baseline;
//     font-weight: normal;
//     content: "\F196";
// }
// .message_content .message_icon::before {
//     font-size: 1.5rem;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     -webkit-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);
// }
// .message_icon{
//     padding: 15px;
//     background-color: #75a01f;
//     border-radius: 30px;
// }
.message_content .message_text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;
}

.message_type-promoApplied .message_heading {
    color: #75a01f;
}

.app_heading2 {
    -webkit-font-feature-settings: 'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1;
    font-feature-settings: 'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1;
    margin: 0;
    color: #333;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: 'Roboto Slab', serif;
    text-transform: none;
    line-height: 1.3;
}

.hotel_select {
    display: block;
    // width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@media screen and (max-width: 425px) {
    .search-bar-container_top {
        display: block;
    }
    .search-bar-container_checkIn,
    .search-bar-container_checkOut {
        width: 100%;
    }
}

.custom-header {
    background-color: #a52019 !important;
}

.is-selected-day-from,
.is-selected-day-to {
    background-color: #a52019 !important;
}

.is-selected-day-range {
    color: #a52019!important;
    background-color: #d7dac1!important;
}

.react-calendar-datetime-picker .daysList .is-today {
    box-shadow: inset 0px 0px 0px 2px #a52019!important;
}

// fieldset {
//     &.MuiOutlinedInput-notchedOutline {
//         border:none;
//     }
// }
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #a52019!important;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    // height: 100vh;
    /* Adjust as needed */
}

.grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f0f0f0;
    /* Optional background for better visibility */
}

.grid-item img {
    width: 100%;
    height: 80%;
    /* Adjust to make space for caption */
    object-fit: cover;
    transition: transform 0.3s ease;
}

.caption1 {
    text-align: center;
    padding: 10px 0;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 16px;
}

.gallery-item {
    flex: 1 1 calc(25% - 32px);
    /* Four items per row with gap */
    box-sizing: border-box;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.gallery-item img:hover {
    transform: scale(1.05);
}

.gallery-image img:hover {
    transform: scale(1.05);
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.grid-container-video {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    // height: 100vh;
}


@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: 100%;
    }
    .grid-container-video {
        grid-template-columns: 1fr;
    }
}