.desktop-table {
    table {
        th {
            &:nth-child(1) {
                width: 20%;
            }

            &:last-child {
                width: 20%;
            }
        }
    }
}