.panel-countdown {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background-color: #a52019;
    z-index: 100;
    // opacity: 0.7;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .panel-countdown-inner {
        color: white;
        font-size: 20px;

        span {
            margin-right: 25px;
        }
    }
    .button-main {
        // display: inline-block;
        -webkit-border-radius: 300px;
        border-radius: 300px;
        padding: 10px 25px;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
    }
}

.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
}

.time_circles > div {
    position: absolute;
    text-align: center;
}

.time_circles > div > h4 {
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Century Gothic', Arial;
}

.time_circles > div > span {
    display: block;
    width: 100%;
    text-align: center;
    font-family: 'Century Gothic', Arial;
    font-size: 300%;
    margin-top: 0.4em;
    font-weight: bold;
}

#register {
    border:1px solid #ffff;
}

@media screen and (max-width: 991px){
    #sponsor{
        color: white;
    }
}

.form-select {
    // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232596be' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    // color: #2596be;
    // background-size: 16px 12px;
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    // border: 1px solid #ced4da;
    // border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.wrapper-bottom{
    margin-bottom: 200px;
}

video {
    width: 100%;
    height: auto;
  }


.btnPaypal{
    cursor: pointer;
}


th {
    width: 25%;
}

.cookies-fibs{
    align-items: baseline;
    background: rgb(53, 53, 53);
    color: white;
    display: flex;
    flex-wrap: wrap;
    right: 0px;
    position: fixed;
    // width: 25%;
    z-index: 999;
    justify-content: end;
    bottom: 0px;

    .cookies-fibs-content{
        flex: 1 0 300px;
        margin: 30px;
    }

    #rcc-confirm-button{
        background: rgb(255, 212, 45);
        border: 0px;
        border-radius: 0px;
        box-shadow: none;
        color: black;
        cursor: pointer;
        flex: 0 0 auto;
        padding: 5px 10px;
        margin: 15px;
    }
}

.gold{
    color: #d4af37;
    img{
        margin-bottom: 2rem;
    }
}

.platinum {
    color: #71706E;
     
    img{
    margin-bottom: 3rem;
    }
}