.bg-header-grey {
    background-color: #555555;
    font-family: 'Century Gothic', Arial;
}

.flip-y {
    transform: rotateZ(180deg) !important;
}

.start-0 {
    left: 0 !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.contact-us-textarea {
    display: block;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
    height: 70px;
}

.fill-primary, .fill-primary * {
    fill: #a52019!important;
}

.width-18x {
    width: 13rem!important;
}

.icons-scheduler {
    background-color: #a52019;
    padding: 20px;
    border-radius: 36px;
}